/** REACT */
import React, { useRef } from "react";
/** STYLE */
import { makeStyles, fade } from "@material-ui/core/styles";
/** CORE */
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
/** LIBRAIRIE EXTERNE */
/** ICON */
import SearchIcon from "@material-ui/icons/Search";
/** COMPOSANT */
/** CONTEXT */
/** FONCTION */
/** CONST */


const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        maxWidth: 300,
        backgroundColor: fade(theme.palette.common.white, 0.90),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 1),
        },
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,

    },
}));

export default function SearchInput({ placeholder, onChangeText, onKeyDownText }) {
    const classes = useStyles();
    const inputRef = useRef(null)

    return (
        <Paper component="form" className={classes.root}>
            <InputBase
                className={classes.input}
                placeholder={placeholder}
                inputProps={{ "aria-label": "multi-search" }}
                inputRef={inputRef}
                onChange={onChangeText}
                onKeyDown={onKeyDownText}
            />
            <IconButton
                //type="submit"
                aria-label="search"
                color="primary"
            // onClick={handleClickLoading}
            >
                <SearchIcon fontSize='small' />
            </IconButton>


        </Paper>
    );
}
