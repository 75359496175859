/** REACT */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
/** STYLE */
import "./style/custom-material-icon.css"
/** CORE */
/** LIBRAIRIE EXTERNE */
/** ICON */
/** COMPOSANT */
import PublicHome from './components/PublicHome';
import AppRouter from './components/AppRouter';
import AlertMessage from "./components/global/AlertMessage"
import ProtectRoute from "./components/global/ProtectRoute"
import NotifApp from './components/notification/NotifApp';
/** CONTEXT */
import UserContextProvider from './context/UserContext';
import ApiContextProvider from './context/ApiContext';
import AppContextProvider from "./context/AppContext";
import NotificationContextProvider from "./context/NotificationContext"
import { Typography } from "@material-ui/core";
/** DATA */


export default function App() {
  console.log("ENTRY POINT : APP")
  return (
    <AppContextProvider>
      <UserContextProvider>
        <ApiContextProvider>
          <NotificationContextProvider>
            {/* GESTION DES ALERTES */}
            <AlertMessage />
            <NotifApp />
            <Router>
              <Switch>
                <Route exact strict path="/">
                  <PublicHome />
                </Route>

                <ProtectRoute path="/app">
                  <AppRouter />
                </ProtectRoute>
              </Switch>
            </Router>
          </NotificationContextProvider>
        </ApiContextProvider>
      </UserContextProvider>
    </AppContextProvider>
  );
}
