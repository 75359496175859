
/** REACT */
import React from "react";
/** STYLE */
/** CORE */
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
/** LIBRAIRIE EXTERNE */
/** ICON */
/** COMPOSANT */
/** CONTEXT */
import { useAppContext } from "../../context/AppContext";
/** FONCTION */
/** CONST */
import { DEFAULT_MSG_ERROR } from "../../global/const";

export default function AlertMessage() {
    const { openAlertMessage, setOpenAlertMessage, globalAlertMessage, isMobileDevice } = useAppContext()

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlertMessage(false);
    };

    if (!globalAlertMessage) {
        return;
    }
    const vertical = !isMobileDevice ? 'top' : 'bottom'
    const horizontal = !isMobileDevice ? 'right' : 'center'

    return (
        <Snackbar open={openAlertMessage} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}>
            <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity={globalAlertMessage.type}>
                {globalAlertMessage.statut ? globalAlertMessage.statut : ''} {globalAlertMessage.message ? globalAlertMessage.message : DEFAULT_MSG_ERROR}
            </MuiAlert >
        </Snackbar>
    );
}

