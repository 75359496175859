/** REACT */
import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
/** STYLE */
import { makeStyles } from "@material-ui/core/styles";
/** CORE */
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Divider from "@material-ui/core/Divider"
/** LIBRAIRIE EXTERNE */
import Hamburger from "hamburger-react";
/** ICON */
import AppsIcon from '@material-ui/icons/Apps';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Badge from '@material-ui/core/Badge';
/** COMPOSANT */
import PublicHome from "./PublicHome"
import SearchInput from './global/SearchInput'
/** CONTEXT */
import { useUserContext } from '../context/UserContext'
/** DATA */
import { useNotificationContext } from '../context/NotificationContext';
import { useAppContext } from "../context/AppContext";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text,
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    linkNone: {
        textDecoration: 'none',
        "&:visited,&:focus,&:hover,&:link,&:active": {
            textDecoration: 'none',
            color: 'inherit'
        }
    },
    groupButton: {
        display: 'flex',
        marginLeft: theme.spacing(1)
    },
    title: {
        flexGrow: 1,
    },
    avatarSmall: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    toolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    vDivider: {
        backgroundColor: theme.palette.text.primary,
    }
}));

export default function AppNavbar({ title, burger, search, toggleDrawer, toggledDrawer, searchChangeText, searchKeyDown, tools }) {

    let classes = useStyles();
    const history = useHistory()
    const { authentication, handleUserLogout } = useUserContext()
    const { countUnreadNotifications, setCountUnreadNotifications,
        getDatas, getNotifications, notifications } = useNotificationContext();
    const anchorElementRef = useRef(null);
    const [openMenu, setOpenMenu] = useState(false);
    const { openDrawer, setOpenDrawer } = useNotificationContext();
    const { deleteAllWhenDisconnect } = useAppContext();

    useEffect(() => {
        console.log('getDatas', countUnreadNotifications)
        if (countUnreadNotifications === null) {
            getDatas();
        }
    }, [authentication])

    const toggleOpenNotification = () => {
        console.log(openDrawer, 'set open drawer')
        setOpenDrawer(!openDrawer);
    }

    const toggleOpenMenu = () => {
        setOpenMenu(!openMenu)
    };

    const handleClickLogout = () => {
        handleUserLogout();
        toggleOpenMenu();
        deleteAllWhenDisconnect();
    };

    const handleClickIconApp = () => {
        console.log('handleClickIconApp')
        history.push('/app')
        localStorage.setItem("link", '/app')
        return <Link to='/app' />
    }

    return (
        <AppBar position="fixed" className={classes.appBar} >
            <Toolbar className={classes.toolbar}>
                <Box display="flex" alignItems='center'>
                    {burger &&
                        <Hamburger
                            size={18}
                            toggled={toggledDrawer}
                            toggle={toggleDrawer}
                        />}
                    <Box display="flex">
                        <Typography variant="h6">
                            {title}
                        </Typography>
                    </Box>
                </Box>
                {search &&
                    <Box>
                        <SearchInput placeholder='Recherche'
                            onChangeText={searchChangeText}
                            onKeyDownText={searchKeyDown} />
                    </Box>
                }
                {
                    authentication && authentication.isAuth ?
                        (<Box display="flex" >
                            {
                                tools
                            }
                            {
                                tools &&
                                < Divider className={classes.vDivider} orientation="vertical" flexItem />
                            }

                            <div className={classes.groupButton}>

                                <IconButton edge="start" className={classes.rightButton} color='inherit'
                                    onClick={handleClickIconApp}>
                                    <AppsIcon />
                                </IconButton>
                                <IconButton
                                    onClick={toggleOpenNotification}
                                    color="inherit"
                                >
                                    <Badge badgeContent={countUnreadNotifications} color="secondary">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                                <div>
                                    <IconButton
                                        ref={anchorElementRef}
                                        aria-label="user info"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={toggleOpenMenu}
                                        color="inherit"
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElementRef.current}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={openMenu}
                                        onClose={toggleOpenMenu}
                                    >
                                        <MenuItem onClick={handleClickLogout}>Déconnexion</MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </Box>) :
                        <PublicHome />
                }
            </Toolbar>
        </AppBar>
    );
}