/** REACT */
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from 'react-router-dom';
/** STYLE */
import { makeStyles } from '@material-ui/core/styles';
/** CORE */
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import Typography from "@material-ui/core/Typography"
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Tooltip from '@material-ui/core/Tooltip'
import Icon from '@material-ui/core/Icon';
/** LIBRAIRIE EXTERNE */
/** ICON */
/** COMPOSANT */
/** CONTEXT */
import { useAppContext } from '../context/AppContext'
import { useUserContext } from '../context/UserContext'
import { useApiContext } from "../context/ApiContext";
import AppNavbar from "./AppNavbar";
/** DATA */
import { useNotificationContext } from '../context/NotificationContext';

const useStyles = makeStyles((theme) => ({
    paperDashboard: {
        paddingTop: 80,
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    rootDashboardGrid: {
        flexGrow: 1,
        marginTop: theme.spacing(3),
        width: '100%'
    },
    alertPanel: {
        paddingBottom: theme.spacing(2)
    },
    paperGrid: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    /* DashboadCard */
    rootCard: {
        display: 'flex',
        flexDirection: 'row',
        minWidth: 150,
        maxWidth: 265
    },
    actionAreaCard: {
        display: 'flex',
        alignItems: 'stretch',
    },
    bannerCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingTop: theme.spacing(1),
        alignItems: 'center'
    },
    detailCard: {
        flex: '1 1 auto',
        flexDirection: 'column',
    },
    contentCard: {
        textAlign: 'end',
        padding: theme.spacing(1),
    },
    footerCard: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',

    },
    tagCard: {
        display: 'flex',
        flexDirection: 'row'
    },
    tags: {
        marginLeft: 4,
        height: 8,
        width: 20,
        borderRadius: 20,
    },
    actionCard: {
        padding: theme.spacing(1)
    },
}));


const AppBoardCard = ({ title, category, colorEnv, tags, icon, redirectLink }) => {
    const classes = useStyles();
    const history = useHistory()

    const handleClickCard = (link) => {
        history.push(link)
        localStorage.setItem("link", link)
        return <Redirect to={link} />
    }

    return (
        <Card className={classes.rootCard} >
            <CardActionArea className={classes.actionAreaCard} onClick={() => handleClickCard(redirectLink)}>
                <div className={classes.bannerCard} style={{ backgroundColor: colorEnv }} >
                    {icon}
                </div>

                <div className={classes.detailCard} >
                    <CardContent className={classes.contentCard}>
                        <Typography variant="h6" gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {category}
                        </Typography>
                    </CardContent>

                    <Divider variant="middle" />
                    <div className={classes.footerCard}>
                        <div className={classes.tagCard}>
                            <React.Fragment>
                                {
                                    tags && tags.map((tag, index) => {
                                        return (
                                            <Tooltip key={index} title={tag.name} aria-label={tag.name}>
                                                <div className={classes.tags} style={{ backgroundColor: tag.color }} />
                                            </Tooltip>
                                        )
                                    })
                                }
                            </React.Fragment>
                        </div>
                        <div className={classes.actionCard}>

                            &nbsp;


                        </div>
                    </div>
                </div >
            </CardActionArea>
        </Card >
    );
};

const AppBoardCardList = ({ apps }) => {
    const mapTags = new Map();
    //console.log(apps, 'apps')
    if (apps) {
        apps.forEach(app => {
            console.log('app', app)
            const tags = [];
            //console.log('tag', app.tags)
            app.tags.forEach((tag) => {
                tags.push(tag)
            });
            mapTags.set(app.id, tags);
        })
        const listApps = apps.map((app) =>
            <Grid key={`grid-id-${app.titre.toLowerCase()}`} item xs={6} sm={4} md={3} xl={2}>
                <AppBoardCard
                    category={app.categorie}
                    title={app.titre}
                    colorEnv={app.couleur}
                    icon={<Icon style={{ color: app.icon.color, fontSize: 32, margin: 8 }}>{app.icon.name}</Icon>}
                    tags={mapTags.get(app.id)}
                    redirectLink={app.lien}
                />
            </Grid>
        );
        //console.log('list app', listApps)

        return listApps;
    }
    return '';

}

export default function AppBoard() {
    const classes = useStyles();
    const { setIsDataLoading } = useAppContext()
    const { authentication, setAuthentication } = useUserContext()
    const { getDatas } = useNotificationContext()
    const { getApiData } = useApiContext()

    const [apps, setApps] = useState(null)

    useEffect(() => {
        getApiData("/api/v1/app/").then((response) => {
            console.log("RESPONSE_APPBOARD", response)
            setApps(response.data)
            setIsDataLoading(false)
            setAuthentication({ ...authentication, isAuthLoading: false })
        }).catch((error) => {
            console.log("ERROR_APPBOARD", error)
        })
        getDatas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
            <AppNavbar title='GeoSIS67' />
            <Container component="main" >

                <CssBaseline />
                <div className={classes.paperDashboard}>
                    <Typography component="h1" variant="h5">
                        Sélection d'une application
                    </Typography>
                    <div className={classes.rootDashboardGrid}>
                        {/*<div className={classes.alertPanel}>
                        <Alert icon={<BuildIcon fontSize="inherit" />} severity="info">
                            <AlertTitle><strong>Maintenance</strong></AlertTitle>
                            L'application 'ITV COVID19' est en phase de mise à jour,
                            il se peut qu'elle ne soit pas disponible.
                            Désolé  pour la gêne occasionnée.
                        </Alert>
                    </div>*/}
                        <Grid container spacing={3}>
                            <AppBoardCardList apps={apps} />
                        </Grid>
                    </div>
                </div>
            </Container>
        </React.Fragment>
    );
}