/** REACT */
import React, { useState, useEffect } from "react";
import { useHistory, Redirect } from "react-router-dom"
/** STYLE */
import { makeStyles } from "@material-ui/core/styles";
//import "mapbox-gl/dist/mapbox-gl.css";
/** CORE */
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton"
/** LIBRAIRIE EXTERNE */
/** ICON */
import { ReactComponent as LogoSgad } from "../assets/svg/logo-sgad.svg"
import { ReactComponent as FacebookIcon } from "../assets/svg/facebook.svg"
import { ReactComponent as TwitterIcon } from "../assets/svg/twitter.svg"
import { ReactComponent as YouTubeIcon } from "../assets/svg/youtube.svg"
import { ReactComponent as InstagramIcon } from "../assets/svg/instagram.svg"
import { ReactComponent as LinkedinIcon } from "../assets/svg/linkedin.svg"
/** COMPOSANT */
/** CONTEXT */
import { useUserContext } from '../context/UserContext'
/** DATA */

const ARRAY_HOME_IMAGE = ['ovhcloud.jpg', 'grimp67.jpg', 'haguenau.png']

function Copyright() {
    const startYear = '2021'
    const currentYear = new Date().getFullYear().toString()

    return (
        <Typography variant="body2" color="textSecondary" align="center" fontWeight="bolder">
            <Link color="inherit" href="https://www.sdis67.com/">
                SIS67
            </Link>
            {' © '}
            {startYear === currentYear ? `${startYear}` : `${startYear} - ${currentYear}`}
        </Typography>
    );
}

function getRandomImage() {
    let min = 0;
    let max = ARRAY_HOME_IMAGE.length - 1;
    let random = Math.floor(Math.random() * (max - min + 1) + min);
    let obj = {}
    let filename = ARRAY_HOME_IMAGE[random]
    obj.filename = filename
    obj.hashtag = filename.split('.')[0].toUpperCase()
    return obj
}
const useStyles = makeStyles((theme) => ({
    rootHome: {
        height: '100vh',
    },
    label: {
        position: 'relative',
        float: 'right',
        backgroundColor: "white",
        top: 128,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(4, 0, 0, 4)
    },
    login: {
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Roboto Condensed',
        fontWeight: 900,
    },
    entete: {
        marginTop: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(3)
        },
    },
    typography_geosis: {
        color: "#1565c0", //blue800
        fontSize: '2rem'
    },
    typography_67: {
        color: "#d32f2f", //red700
        fontSize: '2rem',
        marginRight: theme.spacing(3)
    },
    typography_social: {
        color: theme.palette.secondary.main,
        fontWeight: "500",
        fontSize: '1.3rem',
        marginLeft: theme.spacing(2)
    },
    titre: {
        marginTop: theme.spacing(8),
        textAlign: 'center'
    },
    content: {
        flexGrow: 1,
        width: "min-content"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(1),
        width: 320
    },
    submit: {
        width: 160,
        borderRadius: 20,
        marginTop: theme.spacing(4)
    },
    footer: {
        marginBottom: theme.spacing(2),
        flexShrink: 1
    },
}));

export default function PublicHome() {
    const classes = useStyles();
    const { handleUserLogin, authentication, setAuthentication } = useUserContext()
    const [image, setImage] = useState('')
    const history = useHistory()

    useEffect(() => {
        setImage(getRandomImage())
    }, [])

    const handleSubmitLogin = (e) => {
        e.preventDefault()
        const username = e.target["username"].value;
        const password = e.target["password"].value;
        console.log("AVANT LOGIN")
        handleUserLogin(username, password)
        console.log("APRES LOGIN", authentication)
    };

    /** AUTHENTIFIE on passe sur le AppBoard */
    if (authentication.isAuth && authentication.login) {
        setAuthentication({ ...authentication, login: false })
        console.log("redirect > /app")
        history.push("/app")
        localStorage.setItem('link', '/app')
        return <Redirect to='/app' />
    }

    //if (authentication.isAuth) return <Redirect to="/app" />
    return (
        <div>
        <Grid container component="main" className={classes.rootHome}>
            <CssBaseline />
            <Grid item sm={4} md={7} style={{ backgroundImage: `url(/img/home/${image.filename})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', }}>
                <Box component="span" className={classes.label} display={{ xs: 'none', sm: 'block' }} elevation={6} textAlign="center" fontSize="overline.fontSize" fontStyle="italic" lineHeight={2}>
                    #POMPIERS67 #DRONE67 #{image.hashtag}
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square >
                <Box className={classes.login}>
                    <Box width='min-content' className={classes.entete}>
                        <Box display="flex" flexDirection="row" alignItems='center' justifyContent='center' border={2} borderColor="grey.500" borderRadius={42} >
                            <div style={{ marginTop: 4, marginLeft: 8, marginRight: 8 }}><LogoSgad style={{ width: 64, height: 64 }} /></div>
                            <div className={classes.typography_geosis}>GeoSIS</div>
                            <div className={classes.typography_67}>67</div>
                        </Box>
                        <Box mt={6} textAlign='center' fontFamily='Roboto Condensed' fontWeight={700} fontSize='2.2rem'>
                            Collaborez avec le SIS 67 !
                        </Box>
                    </Box>
                    <Box className={classes.content}>
                        <form className={classes.form} noValidate onSubmit={handleSubmitLogin}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Identifiant"
                                name="username"
                                autoFocus
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type="password"
                                id="password"
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                className={classes.submit}
                            >
                                Se connecter
                            </Button>
                        </form>
                    </Box>
                    <Box className={classes.footer}>
                        <Box display={{ xs: 'none', sm: 'flex' }} flexDirection="row" alignItems='center' justifyContent='center' borderTop={1} borderColor="secondary.main" /*borderRadius={42}*/ >

                            <div className={classes.typography_social}>Suivez-nous sur </div>

                            <IconButton aria-label="facebook" target="_blank" href="https://www.facebook.com/Sdis67/">
                                <FacebookIcon style={{ width: 24, height: 24, fill: "#d32f2f" }} /> {/*red.700*/}
                            </IconButton>
                            <IconButton aria-label="twitter" target="_blank" href="https://twitter.com/sdis67">
                                <TwitterIcon style={{ width: 24, height: 24, fill: "#d32f2f" }} />  {/*red.700*/}
                            </IconButton>
                            <IconButton aria-label="youtube" target="_blank" href="https://www.youtube.com/channel/UCo6vd9yMGtUBeUfGKHTZ7-Q">
                                <YouTubeIcon style={{ width: 24, height: 24, fill: "#d32f2f" }} />  {/*red.700*/}
                            </IconButton>
                            <IconButton aria-label="instagram" target="_blank" href="https://www.instagram.com/sdis67/">
                                <InstagramIcon style={{ width: 24, height: 24, fill: "#d32f2f" }} />  {/*red.700*/}
                            </IconButton>
                            <IconButton aria-label="linkedin" target="_blank" href="https://fr.linkedin.com/company/sdis67">
                                <LinkedinIcon style={{ width: 24, height: 24, fill: "#d32f2f" }} />  {/*red.700*/}
                            </IconButton>
                        </Box>
                        <Box mt={1}><Copyright /></Box>
                    </Box>

                </Box>

            </Grid>
        </Grid>
        </div>
    );
}