/** REACT */
import React, { useState, createContext, useContext, useLayoutEffect, useEffect } from "react";
/** STYLE */
/** CORE */
/** LIBRAIRIE EXTERNE */
/** ICON */
/** COMPOSANT */
/** CONTEXT */
/** DATA */

const MOBILE_WIDTH = 768//616
const TABLET_WIDTH = 1140

const initialGlobalAlert = {
    message: '',
    type: ''
}

const AppContext = createContext();

export default function AppContextProvider({ children }) {
    const { isMobileDevice, isTabletDevice, isTouchScreenDevice,
        openAlertMessage, setOpenAlertMessage,
        globalAlertMessage, setGlobalAlertMessage,
        isDataLoading, setIsDataLoading, openSockets, setOpenSockets,
        timeoutValue, setTimeoutValue, deleteAllWhenDisconnect, disconnectSocket } = useProviderApp();
    console.log("AppContextProvider")
    return (
        <AppContext.Provider value={{
            isMobileDevice, isTabletDevice, isTouchScreenDevice,
            openAlertMessage, setOpenAlertMessage,
            globalAlertMessage, setGlobalAlertMessage,
            isDataLoading, setIsDataLoading, openSockets, setOpenSockets,
            timeoutValue, setTimeoutValue, deleteAllWhenDisconnect, disconnectSocket
        }}>
            {children}
        </AppContext.Provider>
    );
};

export function useAppContext() {
    console.log("useAppContext")
    return useContext(AppContext)
}

function useProviderApp() {
    const [isMobileDevice, setIsMobileDevice] = useState(false)
    const [isTabletDevice, setIsTabletDevice] = useState(false)
    const [isTouchScreenDevice, setIsTouchScreenDevice] = useState(false)
    const [openSockets, setOpenSockets] = useState([]);
    const [timeoutValue, setTimeoutValue] = useState([]);

    const [openAlertMessage, setOpenAlertMessage] = useState(false)
    const [globalAlertMessage, setGlobalAlertMessage] = useState(initialGlobalAlert)

    const [isDataLoading, setIsDataLoading] = useState(false)

    const handleResize = () => {
        setIsMobileDevice(window.innerWidth <= MOBILE_WIDTH)
        setIsTabletDevice(window.innerWidth > MOBILE_WIDTH && window.innerWidth <= TABLET_WIDTH)
        console.log(`width ${window.innerWidth} px`)
    }

    const disconnectSocket = (type) => {
        const allDeleted = []
        for (var s in openSockets) {
            console.log('socket', openSockets[s])
            if (openSockets[s].type === "detail") {
                allDeleted.push(openSockets[s])
                openSockets[s].close();
            }
        } console.log(allDeleted, "allDeleted")
        allDeleted.forEach((deleted) => {
            const index = openSockets.findIndex((value) => {
                return value === deleted;
            })
            if (index > -1) {
                openSockets.splice(index, 1);
            }
        })
    }


    const deleteAllWhenDisconnect = () => {
        console.log('Close all sockets and timeout')
        for (var s in openSockets) {
            console.log('socket', s)
            openSockets[s].close();
        }
        clearTimeout(timeoutValue);
    }

    useEffect(() => {
        window.addEventListener("beforeunload", (ev) => {
            deleteAllWhenDisconnect();
        });
        setIsTouchScreenDevice('ontouchstart' in document.documentElement)
        setIsMobileDevice(window.innerWidth <= MOBILE_WIDTH)
        setIsTabletDevice(window.innerWidth > MOBILE_WIDTH && window.innerWidth <= TABLET_WIDTH)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useLayoutEffect(() => {
        console.log("useLayoutEffect")

        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, []) // eslint-disable-line 


    return {
        isMobileDevice, isTabletDevice, isTouchScreenDevice,
        openAlertMessage, setOpenAlertMessage,
        globalAlertMessage, setGlobalAlertMessage,
        isDataLoading, setIsDataLoading,
        openSockets, setOpenSockets,
        timeoutValue, setTimeoutValue, deleteAllWhenDisconnect, disconnectSocket
    }
}
