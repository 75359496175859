import { createMuiTheme } from "@material-ui/core/styles"
import { grey, red, blueGrey, yellow, deepPurple, brown, cyan } from "@material-ui/core/colors"
import { frFR } from '@material-ui/core/locale';

export const DefaultTheme = createMuiTheme({
    palette: {
        primary: {
            main: grey[400]
        },
        secondary: {
            main: red[700]
        },
        action: {
            main: yellow[500]
        },
    },
    typography: {
        fontFamily: "Roboto Condensed"
    }
}, frFR);

export const OperationelTheme = createMuiTheme({
    palette: {
        primary: {
            main: red[700]
        },
        secondary: {
            main: blueGrey[200]
        }
    },
    typography: {
        fontFamily: "Roboto Condensed"
    }
}, frFR);


export const PreventionTheme = createMuiTheme({
    palette: {
        primary: {
            main: deepPurple[700]
        },
        secondary: {
            //main: blueGrey[200]
            main: brown[200]
        }
    },
    typography: {
        fontFamily: "Roboto Condensed"
    }
}, frFR);


export const AdministrationTheme = createMuiTheme({
    palette: {
        primary: {
            main: cyan[800]
        },
        secondary: {
            main: blueGrey[900]
        }
    },
    typography: {
        fontFamily: "Roboto Condensed"
    }
}, frFR);

