/** REACT */
import React from "react";
/** STYLE */
/** CORE */
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
/** LIBRAIRIE EXTERNE */
/** ICON */
/** COMPOSANT */
/** CONTEXT */
/** FONCTION */
/** CONST */


const DEFAULT_TEXT = 'Chargement des données'

export default function CircularLoading({ text }) {
    return (
        <Container maxWidth="sm" style={{ marginTop: 96, textAlign: 'center' }}>
            <CircularProgress disableShrink />
            <Typography>{text ? text : DEFAULT_TEXT} </Typography>
        </Container>
    )
}