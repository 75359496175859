/** REACT */
import React, { useState, createContext, useContext } from "react";
/** STYLE */
/** CORE */
/** LIBRAIRIE EXTERNE */
import axios from "axios"
import CryptoJS from "crypto-js";
/** ICON */
/** COMPOSANT */
/** CONTEXT */
import { useAppContext } from "./AppContext";
/** DATA */
import { VAPID_KEY } from "../global/api"


const UserContext = createContext();

const initialAuthentication = {
  isAuthLoading: false,
  isAuth: false,
  checkUser: false,
  login: false,
  user: null,
  token: null
};

export default function UserContextProvider({ children }) {
  const { authentication, setAuthentication,
    handleUserLogin, handleUserLogout, handleUserCheck } = useProviderUser();
  return (
    <UserContext.Provider value={{
      authentication, setAuthentication,
      handleUserLogin, handleUserLogout, handleUserCheck
    }}>
      {children}
    </UserContext.Provider>
  );
};
export function useUserContext() {
  return useContext(UserContext)
}

function useProviderUser() {
  /** context */
  const { setGlobalAlertMessage, setOpenAlertMessage } = useAppContext()

  const [authentication, setAuthentication] = useState(initialAuthentication);
  const { deleteAllWhenDisconnect } = useAppContext();

  const url = process.env.REACT_APP_URL_BACK;
  const handleUserLogin = async (username, password) => {
    console.log("handleUserLogin")
    const body = JSON.stringify({ username, password });
    /** hide message erreur */
    setOpenAlertMessage(false)
    /* flag loading start */
    setAuthentication({ ...authentication, isAuthLoading: true })
    /** LOGIN API */
    axios.post(url + "/api/v1/public/login", body).then((response) => {
      /** gestion du local_storage */
      localStorage.setItem("token", response.data.data.token)
      localStorage.setItem("user", JSON.stringify(response.data.data.user))
      localStorage.setItem("checksum", response.data.data.checksum)
      /** gestion du state utilisateur */
      setAuthentication({
        ...authentication,
        isAuth: true, isAuthLoading: false,
        login: true, checkUser: false,
        token: response.data.data.token, user: response.data.data.user
      })

      Notification.requestPermission()
      if ('safari' in window && 'pushNotification' in window.safari) {
        //var permissionData = window.safari.pushNotification.permission(VAPID_KEY);
        //checkRemotePermission(permissionData);
      } else {

        if ('serviceWorker' in navigator) {
          console.log('serviceWorker in navigator > ok')
          navigator.serviceWorker.register('/notification-v0.0.1.js');
          navigator.serviceWorker.ready.then((register) => {
            var options = {
              userVisibleOnly: true,
              applicationServerKey: VAPID_KEY
            }
            register.pushManager.subscribe(options).then(
              function (pushSubscription) {
                axios.post(url + '/api/v1/app/notification/souscrire/', pushSubscription, { headers: { 'Authorization': 'Token ' + response.data.data.token } }).then((response => {
                  console.log(response, 'response')
                }))

                console.log('push subscription > ok')
                // The push subscription details needed by the application
                // server are now available, and can be sent to it using,
                // for example, an XMLHttpRequest.
              })
          })
        }
      }
    })
      /** AXIOS CATCH ERROR */
      .catch((error) => {
        if (error.response && error.response.data) {
          console.log('response', error.response.data.status);
          handleUserLogout()
          setGlobalAlertMessage({
            type: 'error',
            statut: error.response.data.status,
            message: error.response.data.message,
          })
          setOpenAlertMessage(true)
        }
      })



    /*return (
      axios
        .post()
        .then((response) => {
          //console.log("USER_LOGIN", response)
          localStorage.setItem("token", response.data.data.token)
          localStorage.setItem("user", JSON.stringify(response.data.data.user[0]))
          localStorage.setItem("checksum", response.data.data.user[1])
          setAuthentication({
            ...authentication,
            isAuth: true, isAuthLoading: false, login: true, checkUser: false, token: response.data.data.token, user: response.data.data.user[0]
          })
          return response.data
        }).catch((error) => {
          console.log('login error catch', error)
        })
    )*/



    /*
      Notification.requestPermission().then((result) => {
        if ('serviceWorker' in navigator) {
          console.log('1')
          navigator.serviceWorker.register('/notification-v0.0.1.js').then((test) => {
            var options = {
              userVisibleOnly: true,
              applicationServerKey: VAPID_KEY
            };
            test.pushManager.subscribe(options).then(
              function (pushSubscription) {
                axios.post(URL_API_DEV_DIST + '/app/notification/souscrire/', pushSubscription, { headers: { 'Authorization': 'Token ' + response.data.data.token } }).then((response => {
                  console.log(response, 'response')
                }))
   
                console.log('push subscription')
                // The push subscription details needed by the application
                // server are now available, and can be sent to it using,
                // for example, an XMLHttpRequest.
              }
            ).catch(error => {
              console.log(error);
            });
          }).catch(error => {
            console.log('error in register', error)
          });
        }
      }).catch((error) => {
      console.log('login error catch', error)
    });
  })/*
    .catch(function (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setGlobalAlertMessage({
          type: 'error',
          statut: error.response.data.status,
          message: error.response.data.message,
        })
        setOpenAlertMessage(true)
        console.log("error.response")
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log("error.request")
        setGlobalAlertMessage({
          type: 'error'
        })
        setOpenAlertMessage(true)
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      console.log(error.config);
    })*/
  }

  const fetchUserCheck = (resolve, reject, token, user, checksum) => {
    /** verifie si le token dans le localStorage correspond a l'utilisateur */
    setAuthentication({ ...authentication, isAuthLoading: true })
    console.log("fetchUserCheck > USER =", user)
    console.log("fetchUserCheck > djangouser =", user.djangouser)
    console.log("fetchUserCheck > email =", user.email)
    console.log("fetchUserCheck > firstName =", user.firstName)
    console.log("fetchUserCheck > id =", user.id)
    console.log("fetchUserCheck > lastName =", user.lastName)

    const passPhrase = unescape(encodeURIComponent(user.djangouser + user.email + user.firstName + user.id + user.lastName))
    const hashMD5 = CryptoJS.MD5(passPhrase).toString(CryptoJS.enc.Hex)
    console.log("fetchUserCheck > passPhrase =", passPhrase)
    console.log("fetchUserCheck > local_storage > checksum =", checksum)
    console.log("fetchUserCheck > hashMD5 =", hashMD5.toString(CryptoJS.enc.Hex))
    const options = { headers: { Authorization: 'Token ' + token } }
    console.log("fetchUserCheck > token = ", token)
    return (
      axios.get(url + "/api/v1/public/utilisateur", options)
        .then((response) => {
          console.log("fetchUserCheck > axios > response = ", response)
          /** compare cheksum API et checksum LocalStorage  */
          const checksumUserCheck = response.data.data.checksum

          if (checksum === checksumUserCheck) {
            /** on reste connecté */
            setAuthentication({ ...authentication, isAuthLoading: false, isAuth: true, user: response.data.data.user, token: token })
            resolve(response.data)
          } else {
            /** suspicion on logout */
            handleUserLogout()
            reject("checksum diff")
          }

        })
        .catch((error) => {
          console.log("fetchUserCheck > axios > error = ", error)
          handleUserLogout()
          reject(error)
        })
    )
  }

  const handleUserCheck = () => {
    return new Promise((resolve, reject) => {
      console.log("handleUserCheck > auth.token = ", authentication.token)
      const token = localStorage.getItem('token')
      const user = JSON.parse(localStorage.getItem('user'))
      const checksum = localStorage.getItem('checksum')
      console.log("handleUserCheck > local_storage.user = ", user)
      if (token && user && checksum) {
        fetchUserCheck(resolve, reject, token, user, checksum).then((response) => {
          console.log("handleUserCheck > fetchUserCheck > response = ", response)
          resolve(response)
        })
      } else {
        /** manque des infos dans localStorage : action > logout */
        console.log("handleUserCheck > local_storage.token = xxx NONE xxx ")
        handleUserLogout()
        reject("error_handleusercheck")
      }
    })
  }

  const handleUserLogout = () => {
    console.log("!!!!! LOGOUT !!!! handleUserLogout")
    if (localStorage.getItem('token')) {
      const options = { headers: { Authorization: 'Token ' + localStorage.getItem('token') } }
      axios.post(url + "/api/v1/public/disconnect", {}, options).then((data) => {
        console.log("Disconnect work")
      });
    }
    /* logout l'utilisateur */
    localStorage.removeItem("token")
    localStorage.removeItem("user")
    localStorage.removeItem("link")
    localStorage.removeItem("checksum")
    setAuthentication(initialAuthentication);
    deleteAllWhenDisconnect();
  }

  return {
    authentication, setAuthentication, handleUserLogin, handleUserLogout, handleUserCheck
  }
}
