/** REACT */
import React from "react";
/** STYLE */
import { makeStyles } from "@material-ui/core/styles";
/** CORE */
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import LinearProgress from "@material-ui/core/LinearProgress"
import Typography from '@material-ui/core/Typography'
/** LIBRAIRIE EXTERNE */
/** ICON */
/** COMPOSANT */
/** CONTEXT */
/** DATA */

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    linearProgress: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },

}));


const AppLoader = () => {
    const classes = useStyles();
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography>Chargement de l'application</Typography>
                <div className={classes.linearProgress}>
                    <LinearProgress color="primary" />
                </div>
            </div>
        </Container>
    );
}


export default AppLoader;