/** REACT */
import React from "react";
/** STYLE */
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors"
/** CORE */
import Box from "@material-ui/core/Box"
import Drawer from "@material-ui/core/Drawer";
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar'
import Typography from "@material-ui/core/Typography"
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
/** LIBRAIRIE EXTERNE */
import clsx from "clsx"
import moment from "moment"
import "moment/locale/fr";
/** ICON */
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WhatshotIcon from "@material-ui/icons/Whatshot"
/** COMPOSANT */
import EmptyData from '../global/EmptyData';
/** CONTEXT */
import { useNotificationContext } from '../../context/NotificationContext';
/** DATA */
/** FUNCTION */
import { newline2br } from "../../global/utils"

const DRAWER_WIDTH = 300;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: DRAWER_WIDTH,
        //flexShrink: 0,
    },
    drawerPaper: {
        width: DRAWER_WIDTH,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
        marginTop: 64,
    },
    card: {
        minHeight: 150,
        borderRadius: 0,
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
    },
    cardHeader: {
        paddingBottom: theme.spacing(1)
    },
    cardContent: {
        paddingTop: theme.spacing(1)
    },
    avatar: {
        height: theme.spacing(4),
        width: theme.spacing(4),
    },
    operationnel: {
        color: 'white',
        backgroundColor: red[500]
    }
}));

export default function NotificationDrawer() {
    const classes = useStyles();
    const { openDrawer, setOpenDrawer, notifications } = useNotificationContext();
    const handleDrawerClose = () => {
        setOpenDrawer(false)
    }

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={openDrawer}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronRightIcon />
                </IconButton>
                <Typography variant="button" display="block">Notifications</Typography>
                <Typography></Typography>
            </div>
            <Divider />
            {
                (!notifications || notifications.length <= 0) &&
                <EmptyData comment='Aucune notification' direction='row' dense />
            }
            {
                (notifications && notifications.length > 0) && notifications.map((notification, index) => {
                    return (
                        <Card className={classes.card} key={index} elevation={0} >
                            <CardHeader
                                className={classes.cardHeader}
                                avatar={
                                    <Avatar className={clsx(classes.avatar, classes.operationnel)}>
                                        <WhatshotIcon fontSize='small' />
                                    </Avatar>
                                }
                                action={
                                    <IconButton aria-label="settings" size='small'>
                                        <MoreVertIcon />
                                    </IconButton>
                                }
                                title="Dépassement du seuil de vigilance"//{notification.titre}
                                titleTypographyProps={{ variant: 'button', style: { fontWeight: 600 } }}
                            />
                            <CardContent className={classes.cardContent}>
                                <Box>
                                    <Typography variant='body2'>{newline2br(notification.message)}</Typography>
                                    <Box textAlign='end'>
                                        <Typography variant='caption' color='textSecondary'>{moment(notification.dt_creation, 'YYYYMMDDhhmmss').fromNow()}</Typography>
                                    </Box>
                                </Box>

                            </CardContent>
                        </Card>
                    );
                })


            }
        </Drawer >
    );
}