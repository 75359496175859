/** SEARCH */
const OBJ_ACCENT = {
    a: "á|à|ã|â|À|Á|Ã|Â",
    e: "é|è|ê|É|È|Ê",
    i: "í|ì|î|Í|Ì|Î",
    o: "ó|ò|ô|õ|Ó|Ò|Ô|Õ",
    u: "ú|ù|û|ü|Ú|Ù|Û|Ü",
    c: "ç|Ç",
    n: "ñ|Ñ"
};

const unaccent = (text) => {
    if (text) {
        for (var pattern in OBJ_ACCENT) {
            text = text
                .toString()
                .replace(new RegExp(OBJ_ACCENT[pattern], "g"), pattern);
        }
    }

    return text;
};

export async function searchInArray(array, text) {
    let textUnify = unaccent(text).toLowerCase();
    return array.filter(row => Object.values(row).some((value) => value && unaccent(value).toLowerCase().includes(textUnify)))
}

/** RANDOM 
 *  On renvoie un entier aléatoire entre une valeur min (incluse)
 *  et une valeur max (incluse).
*/
export function getRandomIntInclusive(min = 0, max = 100) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/** OTHER */
export function null2empty(text) {
    return text ? text : ''
}

export const newline2br = function (message) {
    return message.split('\\n').map((part, idx) => {
        return (
            <span key={'part-' + idx}> {part}<br /></span>
        )
    })
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const normalize = (value, min, max) => {
    return (value - min) * 100 / (max - min)
}

/** MAPBOX */
export const getUniqueFeatures = (array, key) => {
    var existingFeatureKeys = {};
    // Because features come from tiled vector data, feature geometries may be split
    // or duplicated across tile boundaries and, as a result, features may appear
    // multiple times in query results.
    return array.filter(function (el) {
        if (existingFeatureKeys[el.properties[key]]) {
            return false;
        } else {
            existingFeatureKeys[el.properties[key]] = true;
            return true;
        }
    });
}
