/** REACT */
import React, { createContext, useContext } from "react";
/** STYLE */
/** CORE */
/** LIBRAIRIE EXTERNE */
import { w3cwebsocket as W3CWebSocket } from "websocket";
import axios from "axios"
import { Subject } from 'rxjs';
/** ICON */
/** COMPOSANT */
/** CONTEXT */
import { useUserContext } from "./UserContext"
import { useAppContext } from "./AppContext";
/** DATA */
import { ENDPOINT_WS, URL_HERE_ROUTER } from '../global/api';

const url = process.env.REACT_APP_URL_BACK;
const ApiContext = createContext();

export default function ApiContextProvider({ children }) {
    const { getApiData, connectToWebSocket, getHereApiRouter } = useProviderApi();

    return (
        <ApiContext.Provider value={{ getApiData, connectToWebSocket, getHereApiRouter }}>
            {children}
        </ApiContext.Provider>
    );
};

export function useApiContext() {
    return useContext(ApiContext);
}

function useProviderApi() {
    const url = process.env.REACT_APP_URL_BACK;
    const urlWs = process.env.REACT_APP_WS_BACK;
    const { setIsDataLoading, setGlobalAlertMessage, setOpenAlertMessage, openSockets, setOpenSockets } = useAppContext()
    const { authentication, handleUserLogout, handleUserCheck } = useUserContext()


    const fetchDataApi = (resolve, reject, endpoint, token) => {

        const options = {
            headers: {
                Authorization: "Token " + (token ? token : authentication.token)
            }
        }
        console.log("Authorization: Token ", options)
        setIsDataLoading(true)

        axios.get(url + endpoint, options).then((response) => {
            console.log("fetchDataApi > ", response)
            if (response.status === 200) {
                setIsDataLoading(false)
                resolve(response.data)
            }
        }).catch((error) => {
            console.log("AXIOS_ERROR", error)
            setIsDataLoading(false)
            if (error.response) {
                setGlobalAlertMessage({
                    type: 'error',
                    statut: error.response.data.status,
                    message: error.response.data.message,
                })
                setOpenAlertMessage(true)
            }
            reject(error)
        })
    }

    function connectToWebSocket(channel, type) {
        console.log('url ws', urlWs + ENDPOINT_WS + channel)
        const client = new W3CWebSocket(urlWs + ENDPOINT_WS + channel);
        if (type) {
            client.type = type;
        }
        setOpenSockets([...openSockets, client]);
        const subject = new Subject();
        client.onopen = () => {
            console.log('WebSocket Client ' + channel + ' Connected');
            client.send('{"token": "' + authentication.token + '"}')
        };
        client.onmessage = (message) => {
            console.log('Message incoming from websocket channel ', channel, ' ', message);
            const data = JSON.parse(message.data);
            subject.next(data.message.text);
        };
        client.onclose = () => {
            console.log('WebSocket Client ' + channel + ' closed');
        };
        return subject;
    }

    function getApiData(endpoint) {
        return new Promise((resolve, reject) => {
            if (authentication.token) {
                console.log("GETAPIDATA > auth ok")
                fetchDataApi(resolve, reject, endpoint)
            } else {
                handleUserCheck().then((response) => {
                    console.log("GETAPIDATA > handleCUserCheck", response)
                    fetchDataApi(resolve, reject, endpoint)
                    resolve(response)
                }).catch(error => {
                    handleUserLogout()
                    reject(error)
                })
            }
        })
    }

    const getHereApiRouter = (startX, startY, endX, endY, transportMode) => {

        // setIsDataLoading(true)
        //https://router.hereapi.com/v8/routes?apiKey=GWFL2mvrSK5VEfibmUcQ2cjABs3sU4yrNbCk49iQAqk&routingMode=fast&transportMode=car&origin=48.716882,7.7570343&destination=48.6986279,7.7843979&return=summary
        const url = URL_HERE_ROUTER + `&routingMode=fast&transportMode=${transportMode}&origin=${startX},${startY}&destination=${endX},${endY}&return=summary`
        console.log("getHereApiRouter > getTimeToGo  > url = ", url)
        axios.get(url).then((response) => {
            console.log("getHereApiRouter > ", response)
            if (response.status === 200) {
                setIsDataLoading(false)
                return response.data
            }
        }).catch((error) => {
            console.log("AXIOS_ERROR", error)
            setIsDataLoading(false)
            if (error.response) {
                setGlobalAlertMessage({
                    type: 'error',
                    statut: error.response.data.status,
                    message: error.response.data.message,
                })
                setOpenAlertMessage(true)
            }
            return "error"
        })
    }

    return { getApiData, connectToWebSocket, getHereApiRouter };
}
