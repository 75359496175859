/** REACT */
import React from "react";
/** STYLE */
/** CORE */
import Box from "@material-ui/core/Box"
import Typography from '@material-ui/core/Typography';
/** LIBRAIRIE EXTERNE */
/** ICON */
import NotInterestedIcon from '@material-ui/icons/NotInterested';
/** COMPOSANT */
/** CONTEXT */
/** DATA */

const DEFAULT_COMMENT = "Aucun enregistrement"

export default function EmptyData({ comment, direction = 'column', dense }) {

    return (
        <Box mt={dense ? 1 : 3} display='flex' flexDirection={direction} textAlign='center' alignItems='center' justifyContent='center'>
            <NotInterestedIcon color='primary' />
            <Typography component='div' variant="body2" color="textSecondary">
                {comment ? comment : DEFAULT_COMMENT}
            </Typography>
        </Box>
    )
}

