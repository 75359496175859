/** REACT */
import React, { Suspense, lazy } from "react";
import { Switch, useRouteMatch, Route } from 'react-router-dom';
/** STYLE */
import { ThemeProvider } from "@material-ui/core/styles"
import { PreventionTheme, OperationelTheme, AdministrationTheme } from "../style/theme";
/** CORE */
/** LIBRAIRIE EXTERNE */
/** ICON */
/** COMPOSANT */
import AppBoard from './AppBoard';
import AppLoader from './AppLoader';
/** CONTEXT */
/** DATA */
/** LAZY */
const PojApp = lazy(() => import('./poj/PojApp'));
const ErpApp = lazy(() => import('./erp/ErpApp'));
const ItvApp = lazy(() => import('./itv/ItvApp'));
const AdminApp = lazy(() => import('./admin/AdminApp'));
const SignalementApp = lazy(() => import('./signalement/SignalementApp'));

export default function AppRouter() {

    let { path } = useRouteMatch();
    console.log("ENTRY POINT : APP_ROUTER > path ", path)
    return (
        <Switch>
            <Route exact path={path}>
                <AppBoard />
            </Route>

            <Route path={`${path}/erp`}>
                <ThemeProvider theme={PreventionTheme}>
                    <Suspense fallback={<AppLoader />}>
                        <ErpApp />
                    </Suspense>
                </ThemeProvider>
            </Route>

            <Route path={`${path}/itv`}>
                <ThemeProvider theme={OperationelTheme}>
                    <Suspense fallback={<AppLoader />}>
                        <ItvApp />
                    </Suspense>
                </ThemeProvider>
            </Route>

            <Route exact path={`${path}/poj`}>
                <ThemeProvider theme={OperationelTheme}>
                    <Suspense fallback={<AppLoader />}>
                        <PojApp />
                    </Suspense>
                </ThemeProvider>
            </Route>

            <Route path={`${path}/signalement`}>
                <ThemeProvider theme={PreventionTheme}>
                    <Suspense fallback={<AppLoader />}>
                        <SignalementApp />
                    </Suspense>
                </ThemeProvider>
            </Route>

            <Route path={`${path}/administration`}>
                <ThemeProvider theme={AdministrationTheme}>
                    <Suspense fallback={<AppLoader />}>
                        <AdminApp />
                    </Suspense>
                </ThemeProvider>
            </Route>

        </Switch >
    );
}