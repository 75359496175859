/** REACT */
import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom'
/** STYLE */
/** CORE */
/** LIBRAIRIE EXTERNE */
/** ICON */
/** COMPOSANT */
import CircularLoading from './CircularLoading'
/** CONTEXT */
import { useUserContext } from '../../context/UserContext'
/** DATA */

export default function ProtectRoute({ children, ...rest }) {
    const { authentication, handleUserCheck, handleUserLogout } = useUserContext()
    const history = useHistory();

    console.log("ProtectRoute", authentication)

    return (

        <Route
            {...rest}
            render={({ location }) => {
                if (authentication.isAuthLoading) {
                    console.log("PR>Loading")
                    return <CircularLoading />;
                } else if (!authentication.isAuth && !authentication.login) {
                    handleUserCheck().then((response) => {
                        console.log("PR > handleUserCheck", response)
                        //resolve(children)
                        return children
                    }).catch(error => {
                        console.log("PR > handleUserCheck > error", error)
                        handleUserLogout()
                        console.log("PR >handleUSerLogout > apres")
                        //reject(error)
                        history.push("/")
                        //localStorage.setItem('link', '/')
                        //return <Redirect to='/' />;
                        return <Redirect to={{ pathname: "/", state: { from: location } }} />
                    })
                    console.log("PR >handleUSerCheck > default return ")
                    // return <Redirect to='/' />;
                } else {
                    console.log("PR>Children")
                    return children
                }
            }
            }
        />
    );
}