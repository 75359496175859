/** REACT */
import React, {useEffect} from "react";
/** STYLE */
import { makeStyles } from "@material-ui/core/styles";
import NotificationDrawer from "./NotificationDrawer";
/** CORE */
/** LIBRAIRIE EXTERNE */
/** ICON */
/** COMPOSANT */
/** CONTEXT */
import { useNotificationContext } from '../../context/NotificationContext';
/** DATA */


const useStyles = makeStyles((theme) => ({

}));

const NotifApp = () => {
    const classes = useStyles();

    return (

        <div className={classes.root}>
            <NotificationDrawer/>
        </div>

    );
}

export default NotifApp;