/** REACT */
import React, { createContext, useContext, useEffect, useState } from "react";
/** STYLE */
/** CORE */
/** LIBRAIRIE EXTERNE */
/** ICON */
/** COMPOSANT */
/** CONTEXT */
import { useApiContext } from "./ApiContext"
/** DATA */

export const NotificationContext = createContext();


export default function NotificationContextProvider({ children }) {
    const {
        countUnreadNotifications, setCountUnreadNotifications,
        getDatas, openDrawer, setOpenDrawer,
        getNotifications,
        notifications, setNotifications
    } = useProviderNotification();
    return (
        <NotificationContext.Provider value={{
            countUnreadNotifications, setCountUnreadNotifications,
            getDatas, openDrawer, setOpenDrawer, getNotifications,
            notifications, setNotifications
        }}>
            {children}
        </NotificationContext.Provider>
    );
};
export function useNotificationContext() {
    return useContext(NotificationContext)
}
function useProviderNotification() {
    /** context externe */
    const { getApiData } = useApiContext()
    const [countUnreadNotifications, setCountUnreadNotifications] = useState(null)
    const [openDrawer, setOpenDrawer] = useState(false);
    const [notifications, setNotifications] = useState(false);

    const getDatas = function () {
        getApiData("/api/v1/app/notification/count/non-lu").then((response) => {
            console.log('notification number', response.data)
            setCountUnreadNotifications(response.data)
        });
        getNotifications()
    }

    const getNotifications = function () {
        getApiData("/api/v1/app/notification/voir?limit=10").then((response) => {
            console.log("useProviderNotification > getNotifications > response.data = ", response.data)
            setNotifications(response.data.notifications)
        });
    }

    return {
        countUnreadNotifications, setCountUnreadNotifications,
        getDatas,
        openDrawer, setOpenDrawer,
        getNotifications,
        notifications, setNotifications
    }
}
